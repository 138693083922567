import React, { useState } from 'react'
import GoTrue from 'gotrue-js'

import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Backdrop,
  Snackbar,
  Typography,
  Container,
  makeStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const auth = new GoTrue({
  APIUrl: 'https://rise.trinet.com/.netlify/identity',
  setCookie: true,
})

const useMediaStyles = makeStyles({
  box: {
    flexDirection: 'column',
    width: '400px',
    margin: '0 auto',
  },
  heading: {
    fontSize: '2.5rem',
    marginBottom: '18px',
  },
})

const AdminTest = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ isError: false })

  const sendToCMS = (user) => {
    if (!user) {
      return null
    }

    window.location.href = '/admin'
    return null
  }

  sendToCMS(auth.currentUser())
  const handleLogin = (evt) => {
    const { email, password } = credentials
    evt.preventDefault()
    setLoading(true)
    auth
      .login(email, password, true)
      .then((user) => {
        setLoading(false)
        sendToCMS(user)
      })
      .catch((err) => {
        setLoading(false)
        setError({
          isError: true,
          message:
            err.json && err.json.error_description
              ? err.json.error_description
              : 'Login error, please try again.',
        })
      })
  }

  const handleChange = (evt) => {
    const { name, value } = evt.target
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }))
  }

  const handleClose = () => {
    setError({ isError: false })
  }

  const classes = useMediaStyles()

  return (
    <Container>
      <Box
        className={classes.box}
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Snackbar open={error.isError} onClose={handleClose}>
          <Alert variant="filled" severity="error">
            {error.message}
          </Alert>
        </Snackbar>
        <Backdrop open={loading}>
          <CircularProgress />
        </Backdrop>
        <Typography variant="h2" className={classes.heading}>
          TriNet Rise
        </Typography>
        <Box component="form" onSubmit={handleLogin}>
          <Box mb={3}>
            <TextField
              label="Email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Password"
              name="password"
              value={credentials.password}
              type="password"
              onChange={handleChange}
              required
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              width="100%"
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default AdminTest
